.user-input-box {
    padding: '0px 0px 10px 0px'; 
    width: '100%';
    float: 'left';
}

.checkboxPolicy{
    margin-top: -20px !important;
}

.privacyPolicyUrl{
    text-decoration: underline;
    color: #007bff;
}
.privacyPolicyUrl:hover{
    color: #0056b3;
}

.custom-check {
    align-items: flex-start !important;
}

.custom-check > span:first-child {
    padding-top: 0;
}

.city-de-de, .state-de-de {
    min-height: 24px;
}

/* .Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
}

.InputElement {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 6px 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.InputElement:focus {
    outline: none;
    background-color: #ccc;
}

.Invalid {
    border: 1px solid red;
    background-color: #FDA49A;
} */