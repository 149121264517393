.recommednation_container h2 {
  font-family: grifo-m-bold;
  font-weight: bold;
  font-size: 20px;
  color: #031d43;
}

.recommednation_container table tbody tr {
  background: #f5f6f7;
}

.recommednation_container table tbody tr:nth-last-child(2) td {
  border: none;
}

.recommednation_container table tbody tr:last-child td {
  background: none !important;
  padding: 10px 0px !important;
}

.recommednation_container table tbody tr:last-child {
  background: none;
}

.recommednation_container table tbody tr:last-child td {
  border: none;
}

.recommednation_container table tr th,
.recommednation_container table tr td {
  padding: 0;
  margin: 0;
  border: none;
  border-spacing: 0;
  white-space: pre-line;
}

.recommednation_container table tr th {
  font-family: grifo-m-bold;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  text-align: center;
  padding: 0 20px;
  margin: 0 auto;
  height: 50px;
  vertical-align: middle;
}

.recommednation_container table tr th.content,
.recommednation_container table tr td.content {
  padding: 10px 15px;
  width: 25%;
}

.recommednation_container table tr td {
  border-bottom: 1px solid #616161;
}

.recommednation_container table tr td:last-child {
  border: none;
}

.recommednation_container table tr th.no-content,
.recommednation_container table tr td.no-content {
  padding: 0 15px;
  background: none;
  border-spacing: 0;
}

.recommednation_container table tr th:first-child,
.recommednation_container table tr td:first-child {
  width: 25%;
}

.recommednation_container table tr th.content {
  background: #616161;
}

/* .recommednation_container table tr th:nth-child(2) {
    background: #F65375 !important;
  }

  .recommednation_container table tr td:nth-child(2) {
    background: #F4AEBD;
  } */
th.recommendedService {
  background: #f65375 !important;
}

td.recommendedService {
  background: #f4aebd !important;
}

.recommednation_container table tr td {
  margin: 0 auto;
  background: #ebecee;
  font: normal 12px nunito-regular;
  color: #373737;
}

.recommednation_container table tr td:first-child {
  background: none;
  font: normal 12px nunito-bold;
  color: #031d43;
}

.recommednation_container .white-btn {
  font-family: nunito-bold;
  min-width: 0;
  color: #f65375;
  background-color: white;
  border: 1px solid #f65375;
  border-bottom-width: 1px;
  border-right-width: 1px;
  height: auto;
  padding: 4px 20px;
  font-size: 12px;
  margin: 15px 0;
  display: block;
}

.recommednation_container .sunset-btn {
  margin-bottom: 0;
  min-width: 0;
  font-size: 12px;
  padding: 4px 20px;
  height: auto;
  font-family: nunito-bold;
  background-color: #f65375;
  color: white;
  margin: 15px auto;
  display: block;
}
