a {
    text-decoration: none;
}
.MuiLinearProgress-barColorPrimary {
    background-color: #F65375 !important;
}

.MuiButton-iconSizeMedium{
    padding-left: 20px;
}
@media only screen and (max-width: 600px) {
    .MuiButton-iconSizeMedium{
        padding-left: 10px;
    }
  }