@font-face {
  font-family: 'grifo-m-bold';
  src: url('../fonts/Grifo-M-Bold.otf'),
    url('../fonts/GrifoM-Bold.eot?#iefix') format('embedded-opentype');
  ;
}

@font-face {
  font-family: 'grifo-m-medium';
  src: url('../fonts/Grifo-M-Medium.otf'),
    url('../fonts/GrifoM-Medium.eot?#iefix') format('embedded-opentype');
  ;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2'),
    url('../fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.eot?#iefix') format('embedded-opentype');
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-feature-settings: 'liga';
}


@font-face {
  font-family: 'nunito-bold';
  src: url('../fonts/NunitoSans-Bold.ttf') format("truetype"),
    url('../fonts/NunitoSans-Bold.eot?#iefix') format('embedded-opentype');
}


@font-face {
  font-family: 'nunito-regular';
  src: url('../fonts/NunitoSans-Regular.ttf') format("truetype"),
    url('https://otissaiaatest.blob.core.windows.net/iaastorage/SearchParameter/Regular-Font.woff') format("woff"),
    url('../fonts/nunito-v10-latin-regular.eot') format('embedded-opentype'),
    url('../fonts/nunito-v10-latin-regular.eot?#iefix') format('embedded-opentype');

  /* src: url('../fonts/NunitoSans-Regular.ttf') format("truetype"),
    url('../fonts/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('http://themes.googleusercontent.com/static/fonts/opensans/v6/DXI1ORHCpsQm3Vp6mXoaTaRDOzjiPcYnFooOUGCOsRk.woff') format('woff');
  ; */
}


@font-face {
  font-family: 'nunito-black';
  src: url('../fonts/NunitoSans-Black.ttf') format("truetype"),
    url('../fonts/NunitoSans-Black.eot?#iefix') format('embedded-opentype');
}

@font-face {
  font-family: 'nunito-semibold';
  src: url('../fonts/NunitoSans-SemiBold.ttf') format("truetype"),
    url('../fonts/NunitoSans-SemiBold.eot?#iefix') format('embedded-opentype');
}