
    .MuiSlider-markLabel {
      width: 65px;
      white-space: normal !important;
    }
    .Custom-thumb {
    background-image: url("./../../../assets/images/handle.png");
    width: 24px !important;
    height: 24px !important;
    background-size: 24px !important;
    background-color: transparent !important;
    margin-top: -9px !important;
    margin-left: -10px !important;
  }
  
  .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover{
    box-shadow: none !important;
  }
  
  .MuiSlider-thumb.MuiSlider-active{
    box-shadow: none !important;
  }
  
