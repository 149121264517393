.swiper-wrapper {
  margin-top: 50px !important;
}
.swiper-pagination {
  text-align: left !important;
}
.swiper-pagination-bullets {
  top: 15px;
}
.swiper-pagination-bullet {
  width: 15px !important;
  height: 15px !important;
}
.swiper-pagination-bullet-active {
  background-color: #f65375 !important;
  outline: none !important;
}
.swiper-pagination-bullet {
  margin: 0 15px 0 0 !important;
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.serviceHeader th {
  background: #616161;
  color: white;
  height: 68px !important;
}
.serviceHeaderFirst th {
  background-color: #f65375;
  color: white;
  height: 68px !important;
}
.serviceBg {
  background-color: #f4f5f7;
}
.serviceBgFirst {
  background-color: #f4aebd !important;
}


tbody tr td:nth-child(1) {
  font: normal 12px nunito-bold;
  color: #031d43;
}

tbody tr td:nth-child(2) {
  font: normal 12px nunito-regular;
  color: #373737;
}

.bottonImg{
  width: 23px;
  height: 30px;
}

.tableContainer tbody td{
  padding: 13px !important;
}

.bottonImg{
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 3px;
  left: 5px;
}